
import { memo } from "react"

export default memo(

    function ResImg({info, ...props}) {

       return (<img src={info.src} width={info.width} height={info.height} draggable="false" {...props} />)

    }


)