function imageInfo(src, w, h) {
    return {
        src: src, width: w, height: h
    }
}


export  {

    imageInfo

}