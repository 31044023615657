import contrall from "./contrall.png"
import contrallapp from "./contrallapp.png"
import sicce from "./sicce.svg"
import playStoreDownload from "./playStoreDownload.svg"
import appStoreDownload from "./appStoreDownload.svg"

import facebook from "./facebook.svg"
import instagram from "./instagram.svg"
import twitter from "./twitter.svg"
import youtube from "./youtube.svg"
import linkedin from "./linkedin.svg"

import { imageInfo as logo } from "../../resourceHelper.js"

const iconLogo = (src) => logo(src, 28, 28);

export default {
    sicce: logo(sicce, 221, 65),
    contrall: logo(contrall, 260, 324 / 1829 * 260),
    contrallapp: logo(contrallapp, 150, 150),

    facebook: iconLogo(facebook),
    instagram: iconLogo(instagram),
    twitter: iconLogo(twitter),
    youtube: iconLogo(youtube),
    linkedin: iconLogo(linkedin),

    appStoreDownload: logo(appStoreDownload, 181, 52),
    playStoreDownload: logo(playStoreDownload, 181, 52)

}