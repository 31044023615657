import { createContext, useContext } from "react";

import localization from '../../Risorse/Traduzioni/Languages';

import flags from "../../Risorse/svg/flags/flags";
import logos from "../../Risorse/svg/logos/logos";
import icons from "../../Risorse/svg/icons/icons";
import images from "../../Risorse/images/images";

import links from "../../Risorse/Links/Links";
import pdfDocs from "../../Risorse/documents/documents";

const
    ResourcesContext = createContext({
        language: "",
        flags,
        logos,
        icons,
        images,
        texts: localization.en,
        localization,
        languages: [""],
        links,
        pdfDocs,
        pdfDocument: pdfDocs.en
    });


export function ResourcesProvider({language, children }) {
    const
        locs = {
            language,
            flags,
            logos,
            icons,
            images,
            links,
            texts: localization[language],
            localization,
            languages: Object.keys(localization),
            pdfDocs,
            pdfDocument: pdfDocs[language]
        };
    return (
        <ResourcesContext.Provider value={locs}>
            {children}
        </ResourcesContext.Provider>
    );
}


export function useResources() {
    return useContext(ResourcesContext);
}