import { memo } from "react"
import './Header.css';
import ResImg from "../ResImg/ResImg";
import LanguageSelect from "../LanguageSelect/LanguageSelect"

import { useResources } from "../../Providers/ResourcesProvider/ResourceProvider";


export default memo(

    function Header(props) {
        const { icons, texts } = useResources();

        var shareData, canShare, sharePage;
        if (false && navigator.share) {
            shareData = {
                title: "Download ContrALL",
                url: window.location.href,
            }
            canShare = navigator.canShare(shareData)
            sharePage = () => navigator.share(shareData)
        }

        return (

            <header {...props}>
                {canShare && <button onClick={sharePage}><ResImg className="header-share" info={icons.share} /></button>}
                <LanguageSelect />
            </header>

        )


    }


)