
import images from "./Risorse/images/images"


export default function Page404() {
    return (
        <>
            `
            <style>
                {`

/*======================
    404 page
=======================*/


.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;

    display: flex;
    flex-flow: column;
    padding-top: 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.four_zero_four_bg {

    height: 650px;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}
                `}
            </style>


            <section class="page_404">
                <img class="four_zero_four_bg" src={images.page404.src} />
                <div>
                    <h3 class="h2">Look like you're lost</h3>
                    <p>the page you are looking for not available!</p>
                </div>
            </section>`
        </>
    )
}