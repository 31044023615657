import { memo } from "react";
import './LanguageOption.css'

import localization from '../../../Risorse/Traduzioni/Languages';

import { useResources } from "../../../Providers/ResourcesProvider/ResourceProvider";
import ResImg from "../../ResImg/ResImg";


function genLocalizationLangNames() {

    const res = { 'en': 'UK' };

    for (const n in localization) {

        if (!res[n]) {

            const st = n.split('_');

            res[n] = (st.length === 1 ? n : st[1]).toUpperCase()

        }

    }

    return res;

}
const langNames = genLocalizationLangNames();


export default memo(

    function LanguageOption({languageCode, className = "", ...props}) {

        const flag = useResources().flags[languageCode]

        return (

            <div className={`language-option ${className}`}>
                <ResImg info={flag}  aria-hidden={true}/>
                <span>{langNames[languageCode]}</span>
            </div>

        )

    }


)