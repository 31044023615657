const langs = {
    en: {
        title: `The innovative
        All-in-One App`,
        description: `Control and program your premium SICCE products with an intuitive user interface anytime from anywhere in the world!`,
        video_title: `Video`,
        quickstart_title: `Quickstart for
        SCUBA Contactless`,
        quickstart_button: `Guidelines PDF`,
        website_title: `Website`,
        website_desc: `For more advice and information,
        visit our official website: `,
        website_button: `www.sicce.com`,
        social_title: `Social`
    },
    it: {
        title: `L'innovativa App
        TUTTO-IN-UNO`,
        description: `Controlla e programma i tuoi prodotti premium SICCE con l'interfaccia utente intuitiva in qualsiasi momento e da qualsiasi parte del mondo!`,
        video_title: `Video`,
        quickstart_title: `Istruzioni per avvio rapido
        di SCUBA Contactless`,
        quickstart_button: `Linee guida PDF`,
        website_title: `Sito web`,
        website_desc: `Per ulteriori consigli e informazioni,
        visita il nostro sito web ufficiale:`,
        website_button: `www.sicce.com`,
        social_title: `Social`
    },
    es: {
        title: `Aplicación innovadora
        todo-en-uno`,
        description: `¡Controla y programa tus productos SICCE  con la interfaz de usuario intuitiva en cualquier momento y desde cualquier parte del mundo!`,
        video_title: `Video`,
        quickstart_title: `Instrucciones de inicio rápido
        de SCUBA Contactless`,
        quickstart_button: `Download PDF`,
        website_title: `Website`,
        website_desc: `Para obtener más consejos e información,
        visite nuestro sitio web oficial:`,
        website_button: `www.sicce.com`,
        social_title: `Social`
    },
    de: {
        title: `Die innovative
        All-in-One App`,
        description: `Steuern und programmieren Sie Ihre Premium SICCE-Produkte mit einer intuitiven Benutzeroberfläche, jederzeit und überall auf der Welt!`,
        video_title: `Video`,
        quickstart_title: `Quickstart für
        SCUBA Contactless`,
        quickstart_button: `Download PDF`,
        website_title: `Website`,
        website_desc: `Weitere Empfehlungen und Informationen finden Sie
        auf unserer offiziellen Website:`,
        website_button: `www.sicce.com`,
        social_title: `Social`
    },
    fr: {
        title: `L'application innovante
        tout-en-un`,
        description: `Contrôlez et programmez tous vos produits premiums SICCE à tout moment et n'importe où dans le monde grâce à l'interface utilisateur intuitive!`,
        video_title: `Video`,
        quickstart_title: `Instructions pour un démarrage rapide
        de SCUBA Contactless`,
        quickstart_button: `Download PDF`,
        website_title: `Website`,
        website_desc: `Pour encore plus de conseils et d'informations,
        visitez notre site officiel:`,
        website_button: `www.sicce.com`,
        social_title: `Social`
    },
    pt: {
        title: `O inovador aplicativo
        All-in-One`,
        description: `Controle e programe seus produtos SICCE premium com uma interface de usuário intuitiva a qualquer hora e em qualquer lugar do mundo!`,
        video_title: `Video`,
        quickstart_title: `Início rápido para
        SCUBA Contactless`,
        quickstart_button: `Download PDF`,
        website_title: `Website`,
        website_desc: `Para mais conselhos e informações,
        visite nosso site oficial:`,
        website_button: `www.sicce.com`,
        social_title: `Social`
    },
    nl: {
        title: `De innovatieve
        All-in-One App`,
        description: `Bedien en programmeer uw premium SICCE-producten met een intuïtieve gebruikersinterface, altijd en overal ter wereld!`,
        video_title: `Video`,
        quickstart_title: `Snelstart voor
        SCUBA Contactless`,
        quickstart_button: `Download PDF`,
        website_title: `Website`,
        website_desc: `Bezoek onze officiële website
        voor meer advies en informatie:`,
        website_button: `www.sicce.com`,
        social_title: `Social`
    }
}


const languages = {

    en_us: langs.en,
    ...langs,


}

export default languages;