import langs from "./Traduzioni/Languages";

const URL =  '/Sicce-ContrAll';


const routes = {
    home: URL,
    404: '/404'
}

for (const l in langs) {
    routes[l] = `${URL}/${l}`
}

export default routes;