import background_mobile from "./background_621x2032-min.png"
import background_tablet from "./background_1194x2032-min.png"
import background_desktop from "./background_2032x2032-min.png"
import page404 from "./404-page.gif"
import { imageInfo } from "../resourceHelper.js"

export default {

    background_mobile: imageInfo(background_mobile, 621, 2032),
    background_tablet: imageInfo(background_tablet, 1194, 2032),
    background_desktop: imageInfo(background_desktop, 2033, 2032),
    page404: imageInfo(page404, 920, 650),
}