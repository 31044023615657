
import './App.css';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import localization from './Risorse/Traduzioni/Languages';
import Header from "./Components/Header/Header";
import { useResources, ResourcesProvider } from "./Providers/ResourcesProvider/ResourceProvider";
import HSpanner from "./Components/HSpanner/HSpanner";
import H2spanner from "./Components/H2spanner/H2spanner";
import ResImg from "./Components/ResImg/ResImg";

import { useEffect } from 'react';
import Page404 from './404page';

import routes from './Risorse/routes';
import apk from './Risorse/apk/apk';



const availableLangs = Object.keys(localization);
availableLangs.sort((a, b) => a.length - b.length);

const findDefaultLanguage = () => {

  const fullLang = navigator?.language?.replace('-', '_').toLowerCase();

  if (fullLang) {

    for (const lang of availableLangs) {

      if (fullLang.match(lang)) {

        return lang;

      }

    }

  }

  return 'en'

}


export default function App() {


  return (
    <div>
      <Router>
        <Routes>
          {
            Object.keys(localization)
              .map(lang =>
                <Route
                  key={lang}
                  path={routes[lang]}
                  element={<ResourcesProvider language={lang}><LandingPage /></ResourcesProvider>}
                />
              )}
          <Route path={routes.home} element={<Navigate to={`${findDefaultLanguage()}`} />} />
          <Route path={routes[404]} element={<Page404 />} />
          <Route path={'*'} element={<Navigate to={routes[404]} />} />
        </Routes>
      </Router>
    </div >
  )

}

function LandingPage() {

  var { logos, icons, images, texts, links, language, pdfDocument } = useResources();

  useEffect(() => {

    var meta = document.querySelector("meta[name='description']");

    if (!meta) {
      meta = document.createElement('meta');
      meta.setAttribute('type', 'description');
      document.head.appendChild(meta);
    }

    meta.setAttribute('content', texts.description);
    document.documentElement.lang = language;

  }, [language, texts])

  return (
    <div className="app-container" >
      <style>
        {`
          body {
            background: linear-gradient(#4C6471d8, #10191F00), var(--background-image);
            background-color: #10191F;
            background-repeat: no-repeat;
            background-size: cover;
            --background-image: url(${images.background_mobile.src});
          }
          @media screen and (min-width: ${images.background_mobile.width}px) {
            body {
              --background-image: url(${images.background_tablet.src});
            }
          }
          @media screen and (min-width: 1400px) {
            body {
              --background-image: url(${images.background_desktop.src});
            }
          }
          `
        }
      </style>


      <Header />


      <main>

        <h1 className="screen-reader-only" tabIndex={0}>SICCE</h1>

        <section className="c-column">
          <a href={links.SICCEwebsite} target='_blank' rel='noreferrer' draggable={false}><ResImg className="content-logo" info={logos.sicce} /></a>

          <H2spanner text={texts.title} />

          <ResImg info={logos.contrall} alt="ContrALL" />

          <HSpanner text={texts.description} />

          <div className='sicce-card c-column sicce-shadow'>

            <ResImg className="sicce-card-shadow" info={logos.contrallapp} aria-hidden="true" />

            <div className="sicce-card-buttons sicce-card-shadow c-column">
              <a href={links.appAndroid} target="_blank"  rel="noreferrer"draggable={false}><ResImg info={logos.playStoreDownload} alt="Android store" /></a>
              <a href={links.appIOS} target="_blank"  rel="noreferrer"draggable={false}> <ResImg info={logos.appStoreDownload} alt="iOS store" /></a>
            </div>

            <a href={apk}>Download APK</a>

          </div>
        </section>

        <section className="c-column">

          <H2spanner text={texts.video_title} />

          <div style={{ width: "min(100%, 560px)" }}>
            <div style={{ width: "min(100%, 560px)", paddingBottom: 315 * 100 / 560 + '%', position: "relative", background: '#282828' }}>
              <iframe
                className='sicce-shadow'
                title="YouTube video"
                style={{ position: "absolute", display: "block" }} width="100%" height="100%"
                src={`https://www.youtube.com/embed/${links.youtubeVideo}?origin=${window.location.protocol}//${window.location.host}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>

        </section>


        <section className="c-column">

          <H2spanner text={texts.quickstart_title} />
          <div className='sicce-link-button sicce-shadow'>
            <a href={pdfDocument} target="_blank"  rel="noreferrer" draggable={false}>
              {texts.quickstart_button}
              <ResImg info={icons.download} aria-hidden="true" />
            </a>
          </div>

        </section>

        <section className="c-column">

          <H2spanner text={texts.website_title} />

          <HSpanner text={texts.website_desc} />
          <div className='sicce-link-button sicce-shadow'>
            <a href={links.SICCEwebsite} target="_blank"  rel="noreferrer"draggable={false}>
              {texts.website_button}
              <ResImg info={icons.expand} aria-hidden="true" />
            </a>
          </div>
        </section>

        <section className="c-column">

          <H2spanner text={texts.social_title} />

          <ul className="sicce-social-row sicce-shadow-group">
            <li><a href={links.facebook} draggable={false} target="_blank"  rel="noreferrer"title="Facebook"><ResImg info={logos.facebook} alt="Facebook" /></a></li>
            <li><a href={links.instagram} draggable={false} target="_blank"  rel="noreferrer"title="Instagram"><ResImg info={logos.instagram} alt="Instagram" /></a></li>
            <li><a href={links.twitter} draggable={false} target="_blank"  rel="noreferrer"title="Twitter"><ResImg info={logos.twitter} alt="Twitter" /></a></li>
            <li><a href={links.youtube} draggable={false} target="_blank"  rel="noreferrer"title="Youtube"><ResImg info={logos.youtube} alt="YouTube" /></a></li>
            <li><a href={links.linkedin} draggable={false} target="_blank"  rel="noreferrer"title="LinkedIn"><ResImg info={logos.linkedin} alt="LinkedIn" /></a></li>
          </ul>

        </section>

      </main>
    </div>

  )
}



