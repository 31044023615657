import download from "./download.svg"
import expand from "./expand.svg"
import share from "./share.svg"
import chevronDown from "./chevronDown.svg"

import {imageInfo} from "../../resourceHelper.js"

const icon = (src) => imageInfo(src, 24, 24);

export default {

    download: icon(download), expand: icon(expand), share:icon(share), chevronDown: icon(chevronDown)

}