import { memo } from "react"
import './HSpanner.css';



export default memo(

    function HSpanner({text = '', className = '', ...props}) {

        var splits = text.split(/\n\r?/gm)
         return (

            <span className={`h-spanner ${className}`} {...props}>
                {splits.length > 1 ? splits.map((x, i) => <span key={i}>{x}</span>)
                    : text}
            </span>

        )

    }


)