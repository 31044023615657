import { memo } from "react"
import './H2spanner.css';
import HSpanner from "../HSpanner/HSpanner";


export default memo(

    function H2spanner({ text, ...props }) {

        return <h2 {...props}><HSpanner text={text} /></h2>

    }


)