import { memo, useEffect, useMemo, useRef, useState } from "react";

import { useResources } from "../../Providers/ResourcesProvider/ResourceProvider";

import LanguageOption from './LanguageOption/LanguageOption'

import routes from "../../Risorse/routes";

import './LanguageSelect.css'
import ResImg from "../ResImg/ResImg";
import { Link } from "react-router-dom";


export default memo(

    function LanguageSelect({ className = '', ...props }) {
        const { language, languages, icons } = useResources();

        const [isExpanded, setIsExpanded] = useState(false);
        const selRef = useRef();

        const events = useMemo(() => {
            return {
                isOpen: false,
                touchBlock: e => e.stopPropagation(),
                removeDocHandler: () => document.removeEventListener('pointerdown', events.onClose),
                onOpen: () => {
                    if (events.isOpen) return;
                    setIsExpanded(true);
                    document.addEventListener('pointerdown', events.onClose);
                    events.isOpen = true;
                },
                onClose: () => {
                    if (!events.isOpen) return;
                    setIsExpanded(false);
                    events.removeDocHandler();
                    events.isOpen = false;
                }

            }
        }, [])


        useEffect(() => {
            const sel = selRef.current;

            sel.addEventListener('pointerenter', events.onOpen);
            sel.addEventListener('focusin', events.onOpen);
            sel.addEventListener('mouseleave', events.onClose);
            sel.addEventListener('blur', events.onClose);
            sel.addEventListener('pointerdown', events.touchBlock);

            return events.removeDocHandler;
        }, [events])

        useEffect(() => {
            setTimeout(events.onClose);
        }, [language, events])



        return (

            <div ref={selRef} tabIndex={1} className={`language-select sicce-shadow-group ${isExpanded ? 'expanded' : ''} ${className}`} {...props}>
                <div className="language-select-preview language-select-box transparent">
                    <LanguageOption languageCode={language} />
                    <ResImg info={icons.chevronDown} aria-hidden={true} width="10" height="10" />
                </div>
                <ul className="language-select-box">
                    {languages.map(lang => ((lang !== language) &&
                        <li key={lang}>
                            <Link to={routes[lang]} replace={true} draggable={false}>
                                <LanguageOption languageCode={lang} />
                            </Link>
                        </li>))}
                </ul>
            </div>

        )

    }


)