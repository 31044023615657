import en from "./en.svg"
import en_us from "./en_us.svg"
import it from "./it.svg"
import es from "./es.svg"
import fr from "./fr.svg"
import de from "./de.svg"
import pt from "./pt.svg"
import nl from "./nl.svg"

import {imageInfo} from "../../resourceHelper.js"

const flag = (src) => imageInfo(src, 23, 16);

const flagImages = {en, it, es, fr, de, pt, nl, en_us};

/** @type {{[x in keyof flgs]: ReturnType<flag>}} */
const res = {}
Object.keys(flagImages).forEach(k => res[k] = flag(flagImages[k]));

export default res;