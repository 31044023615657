const links =  {
    appIOS: "https://apps.apple.com/it/app/sicce-contrall/id1156665895",
    appAndroid: "https://play.google.com/store/apps/details?id=com.zepping.SICCEContrAll",
    APK: "https://zepping-deploy.s3.eu-central-1.amazonaws.com/sicce-contrall/apk/release/contrall-2.24(83)-release.apk",
    youtubeVideo: "RGyoKs_Rj0k",
    SICCEwebsite: "https://www.sicce.com",
    facebook: "https://www.facebook.com/pages/Sicce-Spa/198838410203192?ref=tn_tnmn",
    instagram: "https://instagram.com/siccegroup?utm_source=ig_profile_share&igshid=v7ok24fqgp7p",
    twitter: "https://twitter.com/SICCE_Group",
    youtube: "http://www.youtube.com/user/SICCEspa",
    linkedin: "https://www.linkedin.com/company/sicce-srl"
}

export default links;