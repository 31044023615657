
import it from "./Guideline_with_ContrALL_Scuba_Contactless_IT.pdf"
import en from "./Guideline_with_ContrALL_Scuba_Contactless_EN.pdf"
import en_us from "./Guideline_with_ContrALL_Scuba_Contactless_EN-US.pdf"
import de from "./Guideline_with_ContrALL_Scuba_Contactless_DE.pdf"
import es from "./Guideline_with_ContrALL_Scuba_Contactless_ES.pdf"
import fr from "./Guideline_with_ContrALL_Scuba_Contactless_FR.pdf"
import pt from "./Guideline_with_ContrALL_Scuba_Contactless_PT.pdf"
import nl from "./Guideline_with_ContrALL_Scuba_Contactless_NL.pdf"

export default { it, en, en_us, de, es, fr, pt, nl }